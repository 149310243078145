import React, { Fragment, useState, useEffect, useMemo } from "react"
import { Dialog, Transition } from '@headlessui/react'
import { graphql } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import Helmet from 'react-helmet'

import _ from "lodash"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faFilter,
    faPepperHot,
    faFish,
    faPiggyBank,
    faLeaf,
    faKiwiBird,
    faTrashCan,
} from '@fortawesome/free-solid-svg-icons'

import smoothscroll from 'smoothscroll-polyfill';
import { func } from "prop-types"

let hrefs = []
let titles = []
let navList = []
let filters = []

const SpeisekartePage = ({ data }) => {
    const [foodList, setFoodList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [open, setOpen] = useState(false)

    const start = () => {
        smoothscroll.polyfill();
        try {
            const Content = Array.from(
                document.getElementsByClassName("foods")
            )[0]
            const H3List = Array.from(Content.getElementsByClassName("category-section"))
            navList = Array.from(document.getElementsByClassName("automenu"));
            hrefs = []
            titles = []
            for (let i = 0; i < H3List.length; i++) {
                hrefs.push(H3List[i].id)
                titles.push(H3List[i].textContent)
            }
            scrollSpy(H3List);
        } catch (e) {
            console.log('ERROR')
            console.log(e)
            return;
        }
    }

    const removeClasses = () => {
        const els = document.getElementsByClassName("currentInnerAnchor");
        if (els[0]) {
            els[0].classList.remove("currentInnerAnchor");
        }   
    };

    function scrollToActive(pos) {
        const automenu = document.getElementsByClassName("automenu");
        automenu[0].scroll({ left: pos - 100, behavior: 'smooth' });
    }

    function getPosition(element) {
        let valuesArr = [];
        for (let i = 0; i < element.length; i++) {
            let yPosition = 0;
            yPosition +=
            element[i].offsetTop - element[i].scrollTop + element[i].clientTop - 300;
            valuesArr.push(yPosition - window.scrollY);
        }
        return valuesArr;
    }

    const scrollSpy = (H3List) => {
        let x = getPosition(H3List);
        for (let i = 0; i < H3List.length; i++) {
            removeClasses();
            if (x[i] <= 0 && (x[i + 1] > 0 || i + 1 === H3List.length)) {
                navList[0].children[i].classList.add("currentInnerAnchor");
                let leftPos = navList[0].children[i].offsetLeft;
                scrollToActive(leftPos);
                return;
            }
        }
    }

    const getAdditive = (product) => {
        if(product.food_additions) {
            return _.filter(product.food_additions, function(food_addition) {
                return food_addition.type == 'additive' || food_addition.type == 'allergen';
            });
        }
        return []
    }

    const getHighlights = (product) => {
        if(product.filters) {
            let html = "";
            return product.filters.map((add) => {
                if (add.short_name == 'hot') {
                    return <FontAwesomeIcon icon={faPepperHot} size="xs" color="Tomato" />
                }
                if (add.short_name == 'fish') {
                    return <FontAwesomeIcon icon={faFish} size="xs" color="CadetBlue" />
                }
                if (add.short_name == 'pig') {
                    return <FontAwesomeIcon icon={faPiggyBank} size="xs" color="#ff82ab" />
                }
                if (add.short_name == 'vegi') {
                    return <FontAwesomeIcon icon={faLeaf} size="xs" color="OliveDrab" />
                }
                if (add.short_name == 'chicken') {
                    return <FontAwesomeIcon icon={faKiwiBird} size="xs" color="#ffb90f" />
                }
            });
        }
        return (null);
    }

    const handleClick = (e, target) => {
        const element = document.getElementById(target);
        if (e) e.preventDefault()
        window.scroll({ top: element.offsetTop, behavior: 'smooth' });
    }

    const getVarianten = (product) => {
        if (product.Variant) {
            return (
                <>
                {product.Variant.map(variant => (
                    <div className="variant item flex pl-10 space-x-5">
                        <div className="name-box flex-grow">
                            <div className="flex items-center gap-x-1">
                                <div className="foodname">
                                    <span className="id pr-1">{variant.sku}.</span>
                                    <span className="name">{variant.name}</span>
                                </div>
                                <div className="inline-flex gap-x-1">
                                    {getHighlights(variant)}
                                </div>
                            </div>
                            <div className="text-gray-500 text-sm">{variant.description}</div>
                        </div>
                        {getPrice(variant)}
                    </div>
                ))}
                </>
            )
        }
    }

    const getPrice = (product) => {
        if (product.price > 0) {
            return (
                <div className="price-box flex-none">
                    <div className="price">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(product.price)}</div>
                </div>
            )
        }
    }

    const getFilterString = (product) => {
        if (product.filters) {
            let filters = _.map(product.filters, 'short_name');
            return _.join(filters, ' ');
        }

        return '';
    }

    useEffect(() => {
        setFoodList(data.allStrapiFood.nodes);
        setFilterList(getFilters());

        document.addEventListener('scroll', _.debounce(start, 100));
    }, [])

    function getFilters() {
        let temp = _.map(data.allStrapiFood.nodes, 'filters');
        let filtersArray = [];
        _.forEach(temp, function(filter) {
            if (filter.length > 0) {
                _.forEach(filter, function(item) {
                    filtersArray.push(item)
                });
            }
        });

        filtersArray = _.uniqBy(filtersArray, 'short_name');

        return filtersArray;
    }

    function getFilteredListByCategory(categoryId) {
        let selectedFilter = _.filter(filterList, {active: true});
        let foodListByCategory = foodList.filter((item) => item.category.id === categoryId);

        if (!selectedFilter.length) {
            return foodListByCategory;
        }

        return getProductsByFilter(foodListByCategory);
    }

    function getSelectedFilters() {
        return _.filter(filterList, {active: true});
    } 

    function getProductsByFilter(products) {
        let selectedFilter = getSelectedFilters();
        let selectedFilterValues = _.map(selectedFilter, 'short_name');

        return _.filter(products, function(item) {
            if (!item.hasOwnProperty('Variant') || !item.Variant.length) {
                let filterValues = _.map(item.filters, 'short_name');
                return selectedFilterValues.some( ai => filterValues.includes(ai) );
            }

            let filteredVariants = getProductsByFilter(item.Variant);

            if (filteredVariants.length > 0) {
                item.Variant = filteredVariants;
                return true;
            }
            return false;
        });
    }

    function getFormatedText(description) {
        if (!description) {
            return '';
        }

        return description.split(`\n\n`).map(text => `<p>${text.replace(/\n/g, `<br>`)}</p>`).join(``);
    }

    // var filteredList = useMemo(getFilteredList, [selectedCategory, sportList]);

    function toggleFilter(filterId) {
        let index = _.findIndex(filterList, {id: filterId});
        filterList[index].active = !filterList[index].active;
        setFilterList([...filterList], filterList);
    }

    function removeAllFilters() {
        filterList.forEach((filter) => { filter.active = false; })
        setFilterList([...filterList], filterList);
        setOpen(false);
    }

    return (
        <Layout>
            <Helmet>
                <body id="kyoto-speisekarte" />
            </Helmet>
            <SEO
                title="Unsere köstliche Sushi Speisekarte, Sushi Menü"
                description="Entdecken Sie unsere vielfältige Speisekarte in der Kyoto Sushi Bar in Paderborn. Genießen Sie frisches und köstliches Sushi, authentische japanische Spezialitäten und eine Auswahl an delikaten Beilagen. Tauchen Sie ein in die Welt der raffinierten Aromen und lassen Sie sich von unserem erstklassigen Service verwöhnen. Besuchen Sie uns und erleben Sie eine kulinarische Reise, die Ihre Geschmacksknospen verzaubern wird."
                keywords="Speisekarte, Sushi, japanische Küche, asiatische Spezialitäten, Paderborn, Restaurant, Takeaway, Menü, Rollen, Nigiri, Sashimi, Maki, Temaki, Uramaki, vegetarisch, Fisch, Meeresfrüchte, frisch, Qualität, Genuss"
            />
            <section className="px-4">
                <div className="text-center py-5">
                    <h1 className="text-2xl font-medium uppercase">Speisekarte</h1>
                    <h2 className="my-4">Sushi und andere japanische Spezialitätet mittendrin in Paderborn</h2>
                </div>
            </section>
            <div className="bg-white sticky top-12 z-10 shadow md:mx-0">
                <ul className="automenu flex gap-2 overflow-x-scroll">
                    {data.allStrapiCategory.edges.map(item => (
                        <li className="whitespace-nowrap py-3 px-2" key={item.node.id}>
                            <a onClick={e => handleClick(e, item.node.id)} href={`#${item.node.id}`}>{item.node.name}</a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="foods px-4 md:px-0">
            <div className="filter sticky h-0">
                <div
                    onClick={() => setOpen(true)}
                    className="wrap shadow inline-block bg-red-500 border border-red-500 rounded-full w-10 h-10 text-center leading-10 mt-4 md:ml-4 cursor-pointer hover:bg-white hover:text-red">
                    <FontAwesomeIcon icon={faFilter} size="s" color="White" />
                </div>
                {getSelectedFilters().length > 0 &&
                    <span
                        className="remove-filter wrap shadow inline-block border border-red-500 rounded-full text-sm bg-red-500 w-5 h-5 text-center leading-5 mt-4 ml-4 cursor-pointer hover:bg-white hover:text-red-500 wtext-white whitespace-nowrap text-shadow ml-2 cursor-pointer"
                        onClick={() => removeAllFilters()}
                    >
                        <FontAwesomeIcon icon={faTrashCan} size="xs" color="White" />
                    </span>
                }
            </div>
                {data.allStrapiCategory.edges.map((category) => {
                    if (getFilteredListByCategory(category.node.strapiId).length == 0) {
                        return (null);
                    }
                    return (
                    <section id={category.node.id} className="category-section page pb-5 md:pb-0">
                        <div className="image -mx-5 md:mx-0">
                            <GatsbyImage
                                fluid={category.node.image?.childImageSharp.fluid}
                                layout={'fullWidth'}
                                quality={80}
                                formats={["AUTO", "WEBP"]}
                                alt="Kyoto Koch"
                                className="block"
                                image={getImage(category.node.image)}
                            />
                        </div>
                        <div className="content md:pt-10 md:pb-16 md:px-10">
                            <div className="text-center py-5">
                                <StaticImage src={category.node.image?.childImageSharp.fluid} alt="" />
                                <h3 className="text-2xl font-medium uppercase top-4 bg-white">{category.node.name}</h3>
                                <p className="my-4" dangerouslySetInnerHTML={{ __html: getFormatedText(category.node.description) }} />
                            </div>
                            <div className="flex flex-col space-y-5">
                                {getFilteredListByCategory(category.node.strapiId).map((food) => {
                                    return (<>
                                        <div className={"item flex space-x-5 " + getFilterString(food)}>
                                            <div className="name-box flex-grow">
                                                <div className="flex items-center gap-x-1">
                                                    <div className="foodname">
                                                        <span className="id pr-1">{food.sku}.</span>
                                                        <span className="name">{food.name}</span>
                                                    </div>
                                                    <div className="inline-flex gap-x-1">
                                                        {getHighlights(food)}
                                                    </div>
                                                </div>
                                                <div className="text-gray-500 text-sm">{food.description}</div>
                                                <div className="allergens inline-flex space-x-1">
                                                    {getAdditive(food).map(add => (
                                                        <div className="inline-block rounded-full bg-gray-100 text-xs px-1 has-tooltip">
                                                            <span className='inline-block tooltip rounded shadow-lg px-1 text-xs bg-black text-white -mt-4'>{add.name}</span>
                                                            {add.short_name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {getPrice(food)}
                                        </div>
                                        {getVarianten(food)}
                                    </>)
                                })}
                            </div>
                        </div>
                    </section>
                    )
                })}
            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                            <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                                    <div className="flex items-start justify-between">
                                        <Dialog.Title className="text-lg font-medium text-gray-900">Filter</Dialog.Title>
                                        <div className="ml-3 flex h-7 items-center">
                                        <button
                                            type="button"
                                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Close panel</span>
                                            X
                                        </button>
                                        </div>
                                    </div>

                                    <div className="mt-8">
                                        <div className="flow-root">
                                            <div role="list">
                                                {filterList.map((filter) => (
                                                    <div
                                                        className={`badge inline-flex items-center bg-gray-100 text-gray-800 mr-2 mb-2 ${filter.active ? "active" : ""}`}
                                                        onClick={() => toggleFilter(filter.id)}
                                                    >
                                                        {filter.name}
                                                        {filter.active &&
                                                            <button type="button" class="inline-flex items-center p-0.5 ml-2 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-gray-300" data-dismiss-target="#badge-dismiss-dark" aria-label="Remove">
                                                                <svg aria-hidden="true" class="w-3.5 h-3.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                                <span class="sr-only">Remove badge</span>
                                                            </button>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-8 flex flex-row">
                                        <button
                                            disabled={!getSelectedFilters().length ? "true" : ""}
                                            onClick={() => removeAllFilters()}
                                            class={`flex-1
                                                bg-white text-gray-900 text-sm font-medium
                                                px-5 py-2.5 mr-2 mb-2
                                                border border-gray-300 rounded-lg
                                                focus:outline-none focus:ring-4 focus:ring-gray-200
                                                ${!getSelectedFilters().length ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-300"}`}
                                        >
                                            Alle Filter löschen
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setOpen(false)}
                                            class="flex-1 focus:outline-none text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                                        >
                                            Sushi zeigen
                                        </button>
                                    </div>

                                </div>
                            </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        </div>
                    </div>
                    </div>
                </Dialog>
                </Transition.Root>
        </Layout>
    )
}

export default SpeisekartePage

export const pageQuery = graphql`  
query FoodQuery {
    allStrapiFood(filter: {status: {eq: true}}, sort: {fields: sku}) {
      group(field: category___id) {
        edges {
          node {
            id
            Variant {
              id
              sku
              description
              name
              price
              status
            }
            category {
              description
              id
              name
              position
            }
            description
            food_additions {
              id
              name
              short_name
              type
            }
            filters {
                id
                name
                short_name
            }
            name
            price
            sku
            status
          }
        }
        field
        fieldValue
      }
      nodes {
        id
            Variant {
              id
              sku
              description
              name
              price
              status
              filters {
                id
                name
                short_name
              }
            }
            category {
              description
              id
              name
              position
            }
            description
            food_additions {
              id
              name
              short_name
              type
            }
            filters {
                id
                name
                short_name
            }
            name
            price
            sku
            status
      }
    }
    allStrapiCategory(sort: {fields: position}) {
        edges {
            node {
                description
                id
                name
                position
                strapiId
                image {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: DOMINANT_COLOR
                        height: 1280
                        formats: AUTO
                        width: 1920
                    )
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
    }
  }
`